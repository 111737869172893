import { graphql } from 'gatsby';
import React from 'react'
import AppLayout from '../../components/AppLayout';
import ContentWithImage from '../../components/ContentWithImage';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import Faq from '../../components/components-group-one/faq';
import HeroSection from '../../components/components-group-one/hero-section';
import HowItWorks from '../../components/components-group-one/how-it-works';
import Reasons from '../../components/components-group-one/reason';
import RelatedUseCases from '../../components/components-group-one/RelatedCases';
import TestimonialBlock from '../../components/components-group-one/TestimonialBlock';
import UseCases from '../../templates/use-cases';
import { imageDataType } from '../prevent-escalations';

function AiAnswerEngine({ data }: { data: imageDataType }) {
    const pageData = {
        seoData: {
            title: "AI-Driven GPT Answer Engine | AptEdge",
            canonicalLink: "https://www.aptedge.io/technology/ai-answer-engine/",
            metaDescription: {
                metaDescription: "Index and search disparate systems including knowledge bases, ticketing platforms, project trackers, and social channels to bring the most relevant answers.",
            },
            openGraphImage: {
                file: {
                    url: "",
                },
            },
        },
        // title: "GPT Answer Engine",
        title: "AI Answer Engine",
        description: "Transform domain knowledge with Answer AI",
        // description: "Transform domain knowledge with AnswerGPT™",
        isTechnology: true,
        heroImage: data.heroImg.childImageSharp.gatsbyImageData,

        reasons: {
            title: "AI-Driven Search",
            subTitle: "Powered by Generative AI",
            // subTitle: "Powered by Generative AI (GPT)",
            content: "Index and search disparate systems including knowledge bases, ticketing platforms, project trackers, and social channels to bring the most relevant answers. Available via a web interface or embedded in common platforms such as Salesforce, Zendesk, and ServiceNow.  AptEdge's proprietary machine learning brings the industry's most accurate results in real time.",
            image: data.reasonImg.childImageSharp.gatsbyImageData,
        },
        testimonial: [
            {
                company: "cisco",
                name: "Charles Coaxum",
                bio: "Former VP, Customer Experience",
                logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
                content: "AptEdge provides fast, accurate answers to all customer problems. We’re bringing AptEdge to our entire company."
            },
            {
                company: "cloud bees",
                name: "Ryan Smith",
                bio: "Sr. Support Manager",
                logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
                content: "AptEdge has been key in bridging silos between tools we use on a daily basis to build software."
            },
        ],
        contentWithImageData: [
            {
                title: "Powered Answer Engine",
                // title: "GPT Powered Answer Engine",
                description: "AptEdge leverages the power of Generative AI to create the industry’s first “Answer Engine” allowing customer care teams to find answers quickly to resolve critical customer issues.",
                contentWithImage: data.contentWithImageOne.childImageSharp.gatsbyImageData,
            },
            {
                isReversed: true,
                title: "Improve Resolution Times for Voice and Chat",
                description: "By providing immediate answers for your agents, improve resolution and handle times by lowering call holds, dead air, and inefficiencies in looking for answers",
                contentWithImage: data.contentWithImageTwo.childImageSharp.gatsbyImageData,
            },
        ],
        dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
    }
    return (
        <AppLayout seoData={pageData.seoData}>
            <HeroSection
                title={pageData.title}
                heroImage={pageData.heroImage}
                description={pageData.description}
                isTechnology={pageData.isTechnology}
            />
            <Reasons reasons={pageData.reasons} />
            <ContentWithImage contentWithImageData={pageData.contentWithImageData} />
            <TestimonialBlock testimonialData={pageData.testimonial} />
            {/* <Faq /> */}
            <CtaBlock ctaTitle={undefined} />


            {/* @ts-ignore */}
            <style jsx>{`
            .gatsby-image-wrapper [data-main-image]{
                will-change: unset !important;
                opacity: 1 !important;
            }
            `}</style>
        </AppLayout>
    )
}

export default AiAnswerEngine;

export const query = graphql`
    query UnifiedSearchQuery {
        reasonImg: file(relativePath: { eq: "technology/unified-search.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/cisco-logo.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/logo-cloudbees.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
        heroImg: file(relativePath: { eq: "technology/technology-home-hero.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE )
        }
        }
        contentWithImageOne: file(relativePath: { eq: "technology/gpt-powered-engine.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE )
          }
          }
          contentWithImageTwo: file(relativePath: { eq: "technology/calc-avg-handle-time.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE )
          }
          }

      }`